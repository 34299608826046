<template>
  <div>
    <v-data-table
      :headers="headers_students_table"
      :items="dataTable"
      :options.sync="options"
      :server-items-length="totalApplications"
      :loading="loading"
      class="elevation-1"
      item-key="applicationId"
      show-expand
      single-expand
      :footer-props="{
        'items-per-page-options': rowsPerPageItems,
      }"
    >
      <!-- NAME -->
      <template v-slot:[`item.student`]="{ item }">
        <router-link
          :to="{
            name: 'applications-form',
            params: { id: item.applicationId },
          }"
        >
          {{ item.student }}
        </router-link>
      </template>
      <!-- PENDING PAYMENT -->
      <template v-slot:[`item.pendingPayment`]="{ item }">
        <v-chip v-if="item.pendingPayment === 'pending'">
          {{ $t("APPLICATION.STATUS.ITEM1") }}
        </v-chip>
        <v-chip color="green" class="text-white" v-else>
          {{ $t("APPLICATION.STATUS.ITEM7") }}
        </v-chip>
      </template>
      <!-- ICON STATE -->
      <template v-slot:[`item.state_icon`]="{ item }">
        <span v-for="(icon, index) in item.state_icon" :key="index">
          <v-tooltip top class="mr-3">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="cursor-auto"
                :class="`color-${item.data[index].status.color}`"
              >
                <font-awesome-icon :icon="icon.icon" />
              </v-btn>
            </template>
            <span>{{ icon.text }}</span>
          </v-tooltip>
        </span>
      </template>
      <!-- STATE -->
      <template v-slot:[`item.state`]="{ item }">
        <v-chip
          :color="item.state.color"
          :class="{ 'text-white': item.state.color }"
        >
          {{ item.state.text }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pt-5 pb-5">
          <v-card>
            <v-card-text>
              <!-- PRODUCT TABLE -->
              <v-data-table
                :headers="headers_product_table"
                :items="item.data"
                hide-default-footer
              >
                <!-- STATE -->
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    :color="item.status.color"
                    :class="{ 'text-white': item.status.color }"
                  >
                    {{ item.status.text }}
                  </v-chip>
                </template>
                <!-- start_date -->
                <template v-slot:[`item.start_date`]="{ item }">
                  {{ item.start_date | moment(campus.date_format) }}
                </template>
                <!-- UPDATED AT -->
                <template v-slot:[`item.updated_at`]="{ item }">
                  {{ item.updated_at | moment(campus.date_format) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ALL_APPLICATIONS_ADMIN } from "@/core/services/store/application/application.module";

export default {
  data() {
    return {
      PENDING: 1,
      totalApplications: 0,
      loading: true,
      rowsPerPageItems: [10],
      pagination: {
        rowsPerPage: 10,
      },
      headers_students_table: [
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM1"),
          value: "applicationId",
          sortable: true,
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM2"),
          value: "student",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM3"),
          value: "nationality",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM4"),
          value: "state",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM8"),
          value: "pendingPayment",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM5"),
          value: "state_icon",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM6"),
          value: "created_at",
          sortable: true,
        },
        {
          text: this.$t("APPLICATION.TABLE.STUDENTS_HEADER.ITEM7"),
          value: "start_date",
          sortable: true,
          align: "center",
        },
      ],
      headers_product_table: [
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM1"),
          value: "Product.name",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM2"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM3"),
          value: "Campus.name",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM4"),
          value: "start_date",
          sortable: false,
        },
        {
          text: this.$t("APPLICATION.TABLE.PRODUCTS_HEADER.ITEM5"),
          value: "updated_at",
          sortable: false,
        },
      ],
      //
      dataTable: [],
      options: {},
      ACCEPTED: 2,
      DECLINED: 3,
      CANCELED: 4,
    };
  },
  props: {
    status: String,
    newHeaders: {
      type: Boolean,
      default: false,
    },
    headersProduct: {
      type: Array,
      default: null,
    },
    headersStudent: {
      type: Array,
      default: null,
    },
    filterBy: {
      type: String,
      default: "all",
    },
  },
  watch: {
    filterBy: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.newHeaders) {
      this.headers_students_table = this.headersStudent;
      this.headers_product_table = this.headersProduct;
    }
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      const { page, sortBy, sortDesc } = this.options;
      let auxSortBy = sortBy.length > 0 ? `&sort_by=${sortBy[0]}` : "";
      let auxType;
      if (sortDesc.length > 0) {
        auxType = sortDesc[0] ? "DESC" : "ASC";
      } else {
        auxType = "DESC";
      }
      let auxSortDesc = sortDesc.length > 0 ? auxType : "DESC";
      const query = `status=${this.status}&page=${page}${auxSortBy}&sort_type=${auxSortDesc}&filter_by=${this.filterBy}`;
      this.$store.dispatch(GET_ALL_APPLICATIONS_ADMIN, query).then(data => {
        this.dataTable = this.formatData(data.data, this.campus.date_format);
        this.totalApplications = data.total;
        this.loading = false;
      });
    },

    getStatus(status) {
      let statusInfo;
      switch (status) {
        case 1:
          statusInfo = {
            color: null,
            text: this.$t("APPLICATION.STATUS.ITEM1"),
          };
          break;
        case 2:
          statusInfo = {
            color: "green",
            text: this.$t("APPLICATION.STATUS.ITEM2"),
          };
          break;
        case 3:
          statusInfo = {
            color: "red",
            text: this.$t("APPLICATION.STATUS.ITEM3"),
          };
          break;
        case 4:
          statusInfo = {
            color: "deep-orange",
            text: this.$t("APPLICATION.STATUS.ITEM4"),
          };
          break;

        case 5:
          statusInfo = {
            color: "teal",
            text: this.$t("APPLICATION.STATUS.ITEM5"),
          };
          break;

        case 6:
          statusInfo = {
            color: "cyan",
            text: this.$t("APPLICATION.STATUS.ITEM6"),
          };
          break;

        default:
          statusInfo = {
            color: null,
            text: this.$t("APPLICATION.STATUS.ITEM1"),
          };
          break;
      }

      return statusInfo;
    },

    getIcon(productTypeId) {
      let iconInfo;
      switch (productTypeId) {
        case 1:
          iconInfo = {
            icon: "book",
            text: this.$t("APPLICATION.ICONS.ITEM1"),
          };
          break;
        case 2:
          iconInfo = {
            icon: "bed",
            text: this.$t("APPLICATION.ICONS.ITEM2"),
          };
          break;
        case 3:
          iconInfo = {
            icon: "car",
            text: this.$t("APPLICATION.ICONS.ITEM3"),
          };
          break;
        case 6:
          iconInfo = {
            icon: "boxes",
            text: this.$t("APPLICATION.ICONS.ITEM4"),
          };
          break;

        default:
          iconInfo = {
            icon: "book",
            text: this.$t("APPLICATION.ICONS.ITEM1"),
          };
          break;
      }

      return iconInfo;
    },

    formatData(data, date_format) {
      const newData = [];
      data?.forEach(item => {
        const { Student, ApplicationProducts, ApplicationStudentData } = item;
        const icon_status = [];
        const auxGeneralStatus = [];
        ApplicationProducts?.forEach((element, index) => {
          const { productTypeId } = element.Product.product_type;
          const { applicationStatusId } = element.ApplicationStatus;
          icon_status.push(this.getIcon(productTypeId));
          ApplicationProducts[index].status = this.getStatus(
            applicationStatusId
          );
          auxGeneralStatus.push(applicationStatusId);
        });

        let generalStatus = 0;
        let auxAccepted = 0;

        if (auxGeneralStatus.length === 1) {
          generalStatus = auxGeneralStatus[0];
        } else {
          auxGeneralStatus.forEach(status => {
            if (status === this.DECLINED) {
              generalStatus = this.DECLINED;
            } else if (status === this.CANCELED) {
              generalStatus = this.CANCELED;
            } else if (status === this.PENDING) {
              generalStatus = this.PENDING;
            } else if (status === this.ACCEPTED) {
              auxAccepted += 1;
            }
          });

          generalStatus =
            auxGeneralStatus.length === auxAccepted
              ? this.ACCEPTED
              : generalStatus;
        }

        newData.push({
          applicationId: item.applicationId,
          student: `${ApplicationStudentData.firstname} ${ApplicationStudentData.lastname}`,
          state_icon: icon_status,
          state: this.getStatus(generalStatus),
          pendingPayment: item.pendingPayment,
          nationality: Student.country.nameTranslation,
          created_at: Vue.moment(String(item.created_at)).format(date_format),
          start_date: Vue.moment(
            String(ApplicationProducts[0].start_date)
          ).format(date_format),
          data: ApplicationProducts,
        });
      });

      return newData;
    },
  },
  computed: {
    ...mapGetters(["campus"]),
    url() {
      return `${process.env.VUE_APP_CDN_PATH}images/flags`;
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #dfdfdf91 !important;
}

.color-green > span > svg {
  color: #4caf50 !important;
}
.color-orange > span > svg {
  color: #ff9800 !important;
}
.color-red > span > svg {
  color: #f44336 !important;
}
.color-deep-orange > span > svg {
  color: #ff5722 !important;
}

.cursor-auto {
  cursor: auto !important;
}
</style>
